/*
 * Custom translucent site header
 */
@font-face {
    font-family: Alibaba-PuHuiTi-Regular;
    src: url(/static/media/Alibaba-PuHuiTi-Regular.fea46b85.otf) format('opentype');
}
/*
 * Custom translucent site header
 */

/*
 * Custom translucent site header
 */

/*
 * Custom translucent site header
 */

/*
 * Custom translucent site header
 */

/*
 * Custom translucent site header
 */

/*
 * Custom translucent site header
 */

/*
 * Custom translucent site header
 */
*, :after, :before {
    box-sizing: border-box
}

audio, canvas, img, video {
    display: block
}

html {
    line-height: 1
}

body {
    margin: 0
}

h1, h2, h3, h4, h5, h6 {
    font-size: inherit;
    font-weight: inherit;
    line-height: 1;
    margin: 0;
    padding: 0
}

a {
    color: inherit;
    text-decoration: none
}

a:focus {
    outline: 0
}

menu, ol, p, small, ul {
    margin: 0;
    padding: 0
}

menu, ol, ul {
    list-style: none
}

blockquote, q {
    margin: 0
}

cite {
    font-style: inherit
}

form {
    margin: 0
}

fieldset {
    border: none;
    margin: 0;
    padding: 0
}

body, html {
    -webkit-text-size-adjust: 100%;
    color: #435a6f;
}

h1, h2, h3, h4, h5, h6 {
    color: #1f4160;
}

code, kbd, samp, tt {
    font-weight: 500
}

.clearfix:after {
    clear: both;
    content: "";
    display: table
}

.bg-tint1 {
    background: #f9f9fb
}

.bg-tint2 {
    background: #f5f6f7
}

.bg-green {
    background: #43af79
}

.color-white, .color-white h1, .color-white h2, .color-white h3, .color-white h4, .color-white h5, .color-white h6, .color-white p {
    color: #fff
}

.clearfix:after, .clearfix:before {
    clear: both;
    content: "";
    display: table
}

.bg-dark {
    background: #425a70
}

.border-bottom-default {
    border-bottom: 1px solid #e4e7eb
}

.border-bottom-muted {
    border-bottom: 1px solid #edf0f2
}

.focus-ring-link {
    position: relative
}

.focus-ring-link:focus-visible:after {
    -webkit-box-shadow: 0 0 0 2px rgba(16, 112, 202, .47);
    border-radius: 3px;
    bottom: -4px;
    box-shadow: 0 0 0 2px rgba(16, 112, 202, .47);
    content: "";
    display: block;
    left: -6px;
    position: absolute;
    right: -6px;
    top: -4px
}

.focus-ring-link-white {
    position: relative
}

.focus-ring-link-white:focus-visible {
    border-bottom: none
}

.focus-ring-link-white:focus-visible:after {
    -webkit-box-shadow: 0 0 0 2px hsla(0, 0%, 100%, .5);
    border-radius: 3px;
    bottom: -4px;
    box-shadow: 0 0 0 2px hsla(0, 0%, 100%, .5);
    content: "";
    display: block;
    left: -6px;
    position: absolute;
    right: -6px;
    top: -4px
}

.TopBar {
    -ms-flex-align: center;
    -webkit-box-align: center;
    -webkit-box-shadow: 0 0 1px rgba(67, 90, 111, .47), 0 2px 4px -2px rgba(67, 90, 111, .3);
    align-items: center;
    background: #fff;
    box-shadow: 0 0 1px rgba(67, 90, 111, .47), 0 2px 4px -2px rgba(67, 90, 111, .3);
    height: 56px;
    padding-left: 16px;
    padding-right: 16px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
    z-index: 9
}

.TopBar, .TopBar-nav {
    display: flex
}

.TopBar-nav {
    flex: 1 1;
    position: relative
}

@media (max-width: 440px),(min-width: 441px) and (max-width: 620px) {
    .TopBar-nav {
        margin-left: 12px
    }
}

@media (min-width: 621px) and (max-width: 980px),(min-width: 981px) {
    .TopBar-nav {
        margin-left: 24px
    }
}

.TopBar-navRight {
    align-items: center;
    display: flex
}

.TopBar-navRight > span {
    margin-right: 16px
}

.TopBar-link {
    align-items: center;
    border-radius: 3px;
    color: #425a70;
    display: inline-flex;
    font-size: 14px;
    height: 36px;
    letter-spacing: .4px;
    line-height: 16px;
    margin-right: 8px;
    padding: 10px 12px;
    text-transform: uppercase
}

.TopBar-link:last-child {
    margin-right: 0
}

.TopBar-link:hover {
    background-color: #435a6f;
    background-color: rgba(67, 90, 111, .06)
}

.TopBar-link.is-active, .TopBar-link:active {
    background-color: #1070ca;
    background-color: rgba(16, 112, 202, .09);
    color: #1070ca
}

.TopBar-link:focus-visible {
    box-shadow: inset 0 0 0 2px rgba(16, 112, 202, .47)
}

.TopBar-link .icon-holder {
}

.TopBar-link .icon-holder + span {
    margin-left: 8px
}

.TopBar-logo {
    width: 7rem;
}

.BottomBar-logo {
    width: 12rem;
}

@media (max-width: 440px) {
    .TopBar-link {
        height: 32px
    }

    .TopBar-link .hide-on-mobile {
        display: none
    }

    .TopBar-link .icon-holder + .hide-on-mobile {
        margin-left: 0
    }
}

.NavGroup + .NavGroup {
    margin-top: 32px
}

.NavGroup a {
    border-radius: 3px;
    color: #425a70;
    display: block;
    font-size: 14px;
    margin: 2px -8px;
    padding: 8px
}

.NavGroup a:hover {
    background-color: #435a6f;
    background-color: rgba(67, 90, 111, .06);
    color: #425a70
}

.NavGroup a:focus-visible {
    box-shadow: inset 0 0 0 2px rgba(16, 112, 202, .47)
}

.NavGroup a.is-active, .NavGroup a:active {
    background-color: #1070ca;
    background-color: rgba(16, 112, 202, .09);
    color: #1070ca
}

.NavGroup-title {
    font-size: 24px;
    font-weight: 500;
    letter-spacing: -.2px;
    line-height: 28px;
    margin-bottom: 16px;
    margin-top: 16px
}

.Playground {
    background-color: #f9f9fb;
    border-radius: 5px;
    overflow: hidden
}

.MDXPage .Playground {
    margin-bottom: 24px;
    margin-top: 24px
}

.Playground[data-isCodeCollapsed=false] {
    background-color: #234361;
    color: #fff
}

.Playground[data-isCodeCollapsed=true] .Playground-header:hover {
    background-color: #ddebf7
}

.Playground-header {
    -moz-user-select: none;
    -ms-flex-align: center;
    -ms-flex-pack: center;
    -ms-user-select: none;
    -webkit-box-align: center;
    -webkit-box-pack: center;
    -webkit-user-select: none;
    align-items: center;
    cursor: pointer;
    display: block;
    display: flex;
    font-size: 14px;
    justify-content: center;
    letter-spacing: 2px;
    padding: 8px;
    position: relative;
    right: 0;
    text-transform: uppercase;
    user-select: none
}

.Playground-preview {
    -webkit-box-shadow: 0 0 0 1px rgba(67, 90, 111, .14);
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 0 0 1px rgba(67, 90, 111, .14);
    color: #66788a;
    margin: 4px;
    padding: 16px
}

.Playground-error {
    background-color: #1f4160;
    border-radius: 5px;
    color: #fff;
    padding: 80px;
    text-align: center
}

.Playground-error p {
    line-height: 1.4
}

.react-live-error {
    -moz-tab-size: 2;
    -ms-hyphens: none;
    -o-tab-size: 2;
    -webkit-hyphens: none;
    background-color: #ec4c47;
    font-size: 14px;
    hyphens: none;
    line-height: 1.6;
    padding: 16px;
    tab-size: 2;
    text-align: left;
    white-space: pre;
    word-break: normal;
    word-spacing: normal;
    word-wrap: normal
}

.Playground-notice {
    font-size: 14px;
    padding: 12px
}

pre.prism-code {
    -moz-tab-size: 2;
    -ms-hyphens: none;
    -o-tab-size: 2;
    -webkit-hyphens: none;
    background: none;
    background-color: #1e3952;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    hyphens: none;
    line-height: 1.6em;
    overflow: auto;
    padding: 12px;
    tab-size: 2;
    text-align: left;
    white-space: pre;
    word-break: normal;
    word-spacing: normal;
    word-wrap: normal
}

pre.prism-code:focus {
    outline: none
}

pre[class*=language-] {
    border-radius: .3em;
    margin: .5em 0;
    overflow: auto;
    padding: 1em
}

:not(pre) > code[class*=language-] {
    border-radius: .3em;
    padding: .1em;
    white-space: normal
}

.token.cdata, .token.comment, .token.doctype, .token.prolog {
    color: #d4d0ab
}

.token.punctuation {
    color: #fefefe
}

.token.constant, .token.deleted, .token.property, .token.symbol, .token.tag {
    color: #ff8985
}

.token.boolean, .token.number {
    color: #43c4d9
}

.token.function {
    color: #72d3e3
}

.token.string {
    color: #45ffa5
}

.token.attr-name, .token.builtin, .token.char, .token.inserted, .token.selector {
    color: #f7d154
}

.language-css .token.string, .style .token.string, .token.entity, .token.operator, .token.url, .token.variable {
    color: #00e0e0
}

.token.atrule, .token.attr-value {
    color: #45ffa5
}

.token.keyword {
    color: #00e0e0
}

.token.important, .token.regex {
    color: gold
}

.token.bold, .token.important {
    font-weight: 700
}

.token.italic {
    font-style: italic
}

.token.entity {
    cursor: help
}

@media screen and (-ms-high-contrast: active) {
    code[class*=language-], pre[class*=language-] {
        background: window;
        color: windowText
    }

    :not(pre) > code[class*=language-], pre[class*=language-] {
        background: window
    }

    .token.important {
        background: highlight;
        color: window;
        font-weight: 400
    }

    .token.atrule, .token.attr-value, .token.function, .token.keyword, .token.operator, .token.selector {
        font-weight: 700
    }

    .token.attr-value, .token.comment, .token.doctype, .token.function, .token.keyword, .token.operator, .token.property, .token.string {
        color: highlight
    }

    .token.attr-value, .token.url {
        font-weight: 400
    }
}

.code, .Content code {
    -webkit-box-shadow: 0 0 0 1px #d2eef3;
    background-color: #f1fbfc;
    border-radius: 2px;
    box-shadow: 0 0 0 1px #d2eef3;
    color: #007489;
    font-size: 90%;
    font-weight: 500;
    padding: 2px 4px
}

.Content li, .Content p, .li, .paragraph {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -.05px;
    line-height: 1.7
}

.Content p, .paragraph {
    margin-bottom: 1em;
    margin-top: 1em
}

.Content li, .li {
    margin-bottom: .2em;
    margin-top: .2em
}

.Content h1, .h1 {
    font-size: 35px;
    line-height: 40px;
    margin-bottom: 4px;
    margin-top: 54px
}

.Content h1, .Content h2, .h1, .h2 {
    font-weight: 500;
    letter-spacing: -.2px
}

.Content h2, .h2 {
    font-size: 28px;
    line-height: 28px;
    margin-bottom: 16px;
    margin-top: 42px
}

.Content h3, .h3 {
    font-size: 21px;
    line-height: 24px;
    margin-bottom: 16px
}

.Content h3, .Content h4, .h3, .h4 {
    font-weight: 500;
    letter-spacing: -.05px;
    margin-top: 32px
}

.Content h4, .h4 {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 4px
}

.Content a, .link {
    color: #1070ca;
    position: relative
}

.Content a:hover, .link:hover {
    text-decoration: underline
}

.Content a:focus-visible:after, .link:focus-visible:after {
    -webkit-box-shadow: 0 0 0 2px rgba(16, 112, 202, .47);
    border-radius: 3px;
    bottom: -2px;
    box-shadow: 0 0 0 2px rgba(16, 112, 202, .47);
    content: "";
    display: block;
    left: -4px;
    position: absolute;
    right: -4px;
    text-decoration: none;
    top: -2px
}

.Link--small {
    font-size: 10px
}

.blockquote, .Content blockquote {
    background-color: #f1faf5;
    border-left: 2px solid #47b881;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -.05px;
    line-height: 22px;
    margin-bottom: 1em;
    margin-top: 1em;
    padding: 12px
}

.blockquote p, .Content blockquote p {
    margin: .5em
}

.Content strong, .strong {
    font-weight: 600
}

.Content ul, .ul {
    list-style-type: disc;
    margin-bottom: 16px;
    margin-left: 1.1em;
    margin-top: 16px
}

.Container {
    margin-left: auto;
    margin-right: auto;
    padding-left: 24px;
    padding-right: 24px;
    width: 1024px
}

.Container--narrow {
    padding-left: 120px;
    padding-right: 120px
}

.Container-nextToSidebar {
    width: 75%;
}

.Hero h1 {
    font-size: 35px;
    font-weight: 600;
    letter-spacing: -.2px;
    line-height: 58px;
    margin-bottom: .4em
}

.Hero p {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 40px
}

.Hero a.Link {
    border-bottom: 2px solid rgba(71, 184, 129, .05);
    color: #47b881
}

.Hero a.Link:hover {
    border-bottom-color: #47b881
}

.Hero a.Link:active {
    \color: #40a674;
    border-bottom-color: #40a674
}

.Hero-inner {
    align-items: center;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    max-width: 1024px;
    padding: 80px 24px
}

.Hero-left, .Hero-right {
    flex: 1 1
}

.Hero-right {
    padding-left: 48px
}

.Hero-right img {
    width: 100%
}

body {
    min-width: 1024px
}

.MainLayout {
    flex-direction: column;
}

.MainLayout, .MainLayout-main {
    display: flex
}

.MainLayout-main {
    flex-direction: row-reverse
}

.MainLayout-content, .MainLayout-contentRight {
    flex: 1 1;
    overflow-y: auto;
    min-height: calc(100vh - 260px) !important;
}

.MainLayout-contentRight {
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    display: flex;
    justify-content: center
}

.MainLayout-sidebar::-webkit-scrollbar {
    width: 4px
}

.MainLayout-sidebar::-webkit-scrollbar-track {
    background: #435a6f;
    background: rgba(67, 90, 111, .041)
}

.MainLayout-sidebar::-webkit-scrollbar-thumb {
    background: #435a6f;
    background: rgba(67, 90, 111, .146)
}

.MainLayout-sidebar::-webkit-scrollbar-thumb:window-inactive {
    background: red;
    background: rgba(255, 0, 0, .4)
}

.MainLayout-sidebar {
    background-color: #f5f6f7;
    overflow-y: auto;
    padding: 24px;
    width: 240px
}

.Sidebar-inner {
    max-width: 260px
}

.MainLayout-main {
    flex: 1 1
}

.PropTypesTable-composes {
    background-color: #f7c653;
    background-color: rgba(247, 198, 83, .114);
    margin-bottom: 24px;
    padding: 12px
}

.PropTypesTable-composes p {
    margin: 0
}

.PropTypeWrapper {
    font-size: 14px;
    line-height: 1.6em;
    margin-bottom: 24px
}

.PropTypeHeading {
    border-bottom: 2px solid rgba(67, 90, 111, .041);
    margin-bottom: 4px;
    padding-bottom: 4px
}

.PropTypeHeading-arrayOf, .PropTypeHeading-propType {
    background-color: #435a6f;
    background-color: rgba(67, 90, 111, .057);
    border-radius: 2px;
    color: #1f4160;
    font-weight: 500;
    padding: 2px 4px
}

.PropTypeHeading-arrayOf {
    margin-left: 12px
}

.PropTypeHeading-name {
    background-color: #14b5d0;
    background-color: rgba(20, 181, 208, .114);
    border-radius: 2px;
    color: #056f8a;
    font-weight: 500;
    margin-right: 12px;
    padding: 2px 4px
}

.PropTypeHeading-required {
    color: #f4450a;
    margin-left: 12px
}

.PropType-value {
    font-size: 14px
}

.PropTypeDescription {
    margin-bottom: 8px
}

.PropTypeTypeValue {
    border-bottom: 2px solid rgba(67, 90, 111, .041);
    margin-bottom: 4px;
    padding-bottom: 4px
}

.Overview {
    margin: 0 auto 160px;
    min-height: 100vh
}

.Overview header {
    margin-bottom: 40px;
    margin-top: 40px
}

.Overview h2 {
    font-size: 32px;
    margin-bottom: 8px
}

.Overview-group {
    margin-bottom: 40px
}

.Overview-groupTitle {
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 1px;
    margin-bottom: 16px;
    opacity: .7;
    text-transform: uppercase
}

.Overview-groupItems {
    display: grid;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    grid-template-columns: 1fr 1fr 1fr 1fr
}

.OverviewItem:hover {
    background-color: #016cd1;
    background-color: rgba(1, 108, 209, .079);
    color: #016cd1;
    margin: -4px;
    padding: 4px
}

.OverviewItem:focus-visible {
    box-shadow: 0 0 0 2px rgba(1, 108, 209, .699);
    margin: -4px;
    padding: 4px
}

.OverviewItem:active {
    -webkit-box-shadow: 0 0 0 2px #016cd1;
    background-color: #016cd1;
    box-shadow: 0 0 0 2px #016cd1;
    color: #fff;
    margin: -4px;
    padding: 4px
}

.OverviewItem-image {
    background-color: #fff;
    border: none;
    display: block;
    height: auto;
    width: 100%
}

.OverviewItem-label {
    display: block;
    line-height: 18px;
    margin-bottom: 16px;
    margin-top: 16px;
    text-align: center
}

.MDXPage-header {
    border-bottom: #e4e7eb
}

.MDXPage-headerContent {
    align-items: center;
    display: flex;
    padding-bottom: 32px;
    padding-top: 32px
}

.MDXPage-headerContent h1 {
    font-size: 35px;
    font-weight: 500
}

.MDXPage-headerContentLeft {
    -ms-flex: 1;
    -ms-flex-align: center;
    -webkit-box-align: center;
    -webkit-box-flex: 1;
    align-items: center;
    display: flex;
    flex: 1 1
}

.MDXPage .Playground {
    margin-bottom: 48px
}

.MDXPage-headerTabs {
    padding-bottom: 16px;
    padding-top: 16px
}

.Features-grid {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 80px;
    margin-top: 80px
}

.Feature-title {
    margin-top: 0
}

.Feature-description {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -.05px;
    line-height: 1.7
}

.PageFooter-inner {
    align-items: center;
    display: flex;
    margin-bottom: 48px;
    margin-top: 48px
}

.PageFooter-left {
    flex: 1 1
}

.PageFooter-right {
    flex: 3 1;
    text-align: right
}

.PageFooter-right p {
    color: #fff;
    color: hsla(0, 0%, 100%, .8);
    font-size: 14px
}

.PageFooter-right a {
    border-bottom: 2px solid hsla(0, 0%, 100%, .2);
    color: #fff;
    color: hsla(0, 0%, 100%, .8)
}

.PageFooter-right a:hover {
    border-bottom: 2px solid #fff;
    color: #fff
}

.Media-grid {
    display: grid;
    grid-gap: 24px;
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 96px;
    margin-top: 64px
}

.MediaItem {
    -webkit-box-shadow: 0 0 1px rgba(67, 90, 111, .47), 0 2px 4px -2px rgba(67, 90, 111, .3);
    -webkit-transition: all .12s;
    background: #fff;
    border-radius: 3px;
    box-shadow: 0 0 1px rgba(67, 90, 111, .47), 0 2px 4px -2px rgba(67, 90, 111, .3);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    top: 0;
    transition: all .12s
}

.MediaItem:hover {
    box-shadow: 0 0 1px rgba(67, 90, 111, .47), 0 5px 8px -4px rgba(67, 90, 111, .3);
    top: -2px
}

.MediaItem:active {
    box-shadow: 0 0 1px rgba(67, 90, 111, .47), 0 2px 4px -2px rgba(67, 90, 111, .3);
    top: 0
}

.MediaItem:focus-visible:after {
    -webkit-box-shadow: 0 0 0 2px rgba(16, 112, 202, .47);
    border-radius: 5px;
    bottom: -8px;
    box-shadow: 0 0 0 2px rgba(16, 112, 202, .47);
    content: "";
    display: block;
    left: -8px;
    position: absolute;
    right: -8px;
    top: -8px
}

.MediaItem-content {
    -ms-flex: 1;
    -webkit-box-flex: 1;
    display: flex;
    flex: 1 1;
    flex-direction: column;
    margin: 12px
}

.MediaItem-title {
    -ms-flex: 1;
    -webkit-box-flex: 1;
    color: #234361;
    flex: 1 1;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.3;
    margin: 0 0 12px
}

.MediaItem-published {
    color: #7b8b9a;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -.05px;
    line-height: 1.5
}

.MediaItem-image {
    display: block;
    width: 100%
}

.NavGroup-nav .active {
    background-color: #e1eaf3;
    color: #0f70ca;
}


/*
 * Custom translucent site header
 */

* {
    font-family: "Alibaba-PuHuiTi-Regular" !important;
}
